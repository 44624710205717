<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/PageHeader/main.vue';
import appConfig from '@/app.config';
import { keyMaster, masterComputed, masterMethods, agencyMethods, checkPermission, checkPermissionName } from '@/state/helpers';
import { email, required, maxLength } from 'vuelidate/lib/validators';
import { regexNumber } from '../../../utils/format';
import { showMessage } from '@/utils/messages';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
/**
 * Advanced-form component
 */
export default {
    page: {
        title: '代理店編集',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Layout,
        PageHeader,
        Footer
    },
    data() {
        return {
            title: '代理店担当者登録',
            index: 1,
            loading: false,
            items: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '代理店一覧',
                    active: true
                },
                {
                    text: this.title ? this.title : '代理店担当者登録',
                    active: true
                }
            ],
            agency_id: this.$route.query.agency_id,
            id: this.$route.query.id,
            form: {
                name: '',
                kana_name: '',
                email: '',
                phone_number: '',
                department: '',
                mobile_number: ''
            },
            partnerData: {},
            listDataMasterGroup: [],
            submitted: false,
            submitform: false,
            submit: false,
            typesubmit: false
        };
    },
    computed: {
        ...masterComputed
    },
    validations: {
        form: {
            name: { required, maxLength: maxLength(255) },
            email: { email, maxLength: maxLength(255) },
            department: { maxLength: maxLength(255) },
            kana_name: {
                maxLength: maxLength(255),
                katakana() {
                    if (this.form.kana_name && this.form.kana_name.length < 255) {
                        const katakanaRegex = /^[\u30A0-\u30FF\s]+$/u;

                        if (katakanaRegex.test(this.form.kana_name)) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return true;
                }
            },
            phone_number: { maxLength: maxLength(20) },
            mobile_number: { maxLength: maxLength(20) }
        }
    },
    mounted() {
        if (this.$route.query.id && this.$route.query.agency_id) {
            let data = {
                agency_id: this.$route.query.agency_id,
                id: this.$route.query.id
            };
            this.getDetail(data);
            this.title = '代理店担当者編集';
            this.items[2].text = '代理店担当者編集';
        }
        if (this.$route.query.agency_id) {
            this.getDetailAgency(this.$route.query.agency_id);
        }
        this.getListMasterGroup();
    },
    methods: {
        checkPermission,
        ...masterMethods,
        ...agencyMethods,

        checkPermiss(listRole) {
            return checkPermissionName(listRole);
        },
        inputPhone(type) {
            if (type === 'phone_number') {
                this.form.phone_number = regexNumber(this.form.phone_number);
            } else if (type === 'mobile_number') {
                this.form.mobile_number = regexNumber(this.form.mobile_number);
            }
        },
        getDetailAgency(id) {
            this.loading = true;
            this.detailAgency(id).then((data) => {
                this.partnerData = data;
                this.loading = false;
            });
        },
        getDetail(id) {
            this.loading = true;
            this.detailAgencySupport(id).then((data) => {
                this.form = data;
                this.loading = false;
            });
        },
        getListMasterGroup() {
            this.listMaster(keyMaster['employee.group']).then((data) => {
                this.listDataMasterGroup = data;
            });
        },
        // eslint-disable-next-line no-unused-vars
        formSubmit(e) {
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) return;
            this.register();
        },
        register() {
            if (this.form.name && !this.loading) {
                this.loading = true;
                let dataObject = Object.assign({}, this.form);
                dataObject.agency_id = this.agency_id;
                if (this.$route.query.id) {
                    this.updateAgencySupport(dataObject).then((data) => {
                        if (data.code == 200) {
                            showMessage(data.code, this.$bvToast, '代理店担当者が更新されました。');
                            this.getDetailAgency(this.$route.query.agency_id);
                        } else {
                            if (data.message === 'name.unique') {
                                showMessage(data.code, this.$bvToast, this.$t('notificationCommon.exitPersonInCharge'));
                            } else {
                                showMessage(data.code, this.$bvToast, this.$t(data.message));
                            }
                            this.loading = false;
                        }
                    });
                } else {
                    this.registerAgencySupport(dataObject).then((data) => {
                        if (data.code == 200) {
                            this.$router
                                .push({
                                    path: `/agency/support/view`,
                                    query: {
                                        id: data.data.id,
                                        agency_id: this.$route.query.agency_id
                                    }
                                })
                                .then(() => {
                                    showMessage(data.code, this.$bvToast, '代理店担当者が登録されました。');
                                });
                        } else {
                            if (data.message === 'name.unique') {
                                showMessage(data.code, this.$bvToast, this.$t('notificationCommon.exitPersonInCharge'));
                            } else {
                                showMessage(data.code, this.$bvToast, this.$t(data.message));
                            }
                        }
                        this.loading = false;
                    });
                }
            }
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row form__body--card form__common--center" v-if="!loading">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-1"></div>
                            <div class="col-sm-6">
                                <form class="needs-validation" @submit.prevent="formSubmit" autocomplete="off">
                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom05">{{ $t('agency.nameCompany') }}</label>
                                        <div class="col-sm-12">
                                            {{ partnerData.name }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom05">{{ $t('agency.department') }}</label>
                                        <div class="col-sm-12">
                                            <input
                                                id="validationCustom05"
                                                v-model="form.department"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.department.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.department.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.department.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: $t('agency.department')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom05"
                                            >{{ $t('agency.manager') }}
                                            <span class="text-danger">*</span>
                                        </label>
                                        <div class="col-sm-12">
                                            <input
                                                id="validationCustom05"
                                                v-model="form.name"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.name.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.name.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.name.required">{{
                                                    $t('validateField.required', {
                                                        field: $t('agency.manager')
                                                    })
                                                }}</span>
                                                <span v-if="!$v.form.name.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: $t('agency.manager')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom05">{{ $t('agency.personChargeKana') }}</label>
                                        <div class="col-sm-12">
                                            <input
                                                id="validationCustom05"
                                                v-model="form.kana_name"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.kana_name.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.kana_name.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.kana_name.katakana && $v.form.kana_name.maxLength">
                                                    {{
                                                        $t('validateField.incorrectFormat', {
                                                            field: $t('agency.personChargeKana')
                                                        })
                                                    }}</span
                                                >
                                                <span v-if="!$v.form.kana_name.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: $t('agency.personChargeKana')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom05">{{ $t('agency.phone_number') }}</label>
                                        <div class="col-sm-12">
                                            <input
                                                id="validationCustom05"
                                                v-model="form.phone_number"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.phone_number.$error
                                                }"
                                                @input="inputPhone('phone_number')"
                                            />
                                            <div v-if="submitted && $v.form.phone_number.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.phone_number.maxLength">{{
                                                    $t('validateField.max20', {
                                                        field: $t('agency.phone_number')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom05">{{ $t('agency.email') }}</label>
                                        <div class="col-sm-12">
                                            <input
                                                id="validationCustom05"
                                                v-model="form.email"
                                                autocomplete="off"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.email.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.email.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.email.email && $v.form.email.maxLength">{{
                                                    $t('validateField.incorrectFormat', {
                                                        field: $t('agency.email')
                                                    })
                                                }}</span>
                                                <span v-if="!$v.form.email.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: $t('agency.email')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom05">{{ $t('agency.cellphoneNumber') }}</label>
                                        <div class="col-sm-12">
                                            <input
                                                id="validationCustom05"
                                                v-model="form.mobile_number"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.mobile_number.$error
                                                }"
                                                @input="inputPhone('mobile_number')"
                                            />
                                            <div v-if="submitted && $v.form.mobile_number.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.mobile_number.maxLength">{{
                                                    $t('validateField.max20', {
                                                        field: $t('agency.cellphoneNumber')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill">
                        <button
                            type="button"
                            class="btn btn-light mr-3 btn-sm"
                            @click="
                                $router.push({
                                    path: `/agency/view?id=${$route.query.agency_id}`
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            v-if="checkPermission('agency.edit') || checkPermission('agency.edit')"
                            type="submit"
                            @click="formSubmit"
                            class="btn btn-primary ml-3"
                        >
                            {{ $route.query.id ? $t('btn.save') : $t('btn.register') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
